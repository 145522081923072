import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import React, { memo, useContext } from 'react';
import * as styles from './Artboard.module.css';
import { useSelector } from '../../../contexts/ResumeContext';
import Castform from '../../../templates/Castform';
import Celebi from '../../../templates/Celebi';
import Gengar from '../../../templates/Gengar';
import Glalie from '../../../templates/Glalie';
import Onyx from '../../../templates/Onyx';
import Pikachu from '../../../templates/Pikachu';
import Tokyo from '../../../templates/Tokyo';
import Icon from '../../../../static/images/template-icon.svg';
// import WhiteDot from '../../../../static/images/white-dot.svg'
import ModalContext from '../../../contexts/ModalContext';
import Amsterdam from '../../../templates/Amsterdam';
import Berlin from '../../../templates/Berlin';

const Artboard = ({ setOpenTemp }) => {
  const localUser = JSON.parse(localStorage.getItem('user'));
  const { isPremiumUser } = localUser;
  const state = useSelector();
  const { t } = useTranslation();
  const { id, name, metadata } = state;
  const { emitter, events } = useContext(ModalContext);
  const { template } = metadata;
  const handleOpenPrintDialog = useReactToPrint({
    content: () => document.getElementById('page'),
  });

  const handleExport = () =>
    !isPremiumUser
      ? emitter.emit(events.SUBSCRIPTION_MODAL)
      : handleOpenPrintDialog();

  const openTemp = () => {
    setOpenTemp(true);
  };

  return (
    <>
      <Helmet>
        <title>{name} | SimpleCV</title>
        <link rel="canonical" href={`https://simplecv.me/app/builder/${id}`} />
      </Helmet>
      <div className={styles.container}>
        <div className="flex justify-between px-4 mt-5 border-b-2 w-[95%] pb-2 pt-4">
          <h1 className="text-[#262B33] text-[18px] font-semibold font-sans">
            {t('shared.resumePreview')}
          </h1>
          <button
            onClick={() => {
              openTemp();
            }}
            className="flex text-[#3346D3] text-[15px] font-sans"
          >
            <img src={Icon} alt="Icon" className="mr-1" />
            <p>{t('shared.buttons.selectOther')}</p>
          </button>
        </div>
        <hr className="my-5 opacity-25" />
        <div id="page" className="w-[95%] border-2 z-0">
          {template === 'onyx' && <Onyx data={state} />}
          {template === 'pikachu' && <Pikachu data={state} />}
          {template === 'gengar' && <Gengar data={state} />}
          {template === 'castform' && <Castform data={state} />}
          {template === 'glalie' && <Glalie data={state} />}
          {template === 'celebi' && <Celebi data={state} />}
          {template === 'tokyo' && <Tokyo data={state} />}
          {template === 'Amsterdam' && <Amsterdam data={state} />}
          {template === 'Berlin' && <Berlin data={state} />}
        </div>
        <div className="flex justify-center space-x-6 px-4 my-5 pb-[70px]">
          <button
            className="fixed bottom-12 w-[335px] h-[56px]  bg-[#3346D3] py-4 px-10 rounded-lg shadow-lg text-white text-[18px]  text-center font-medium	font-inter"
            onClick={handleExport}
          >
            {t('shared.buttons.downloadPDF')}
          </button>

          {/* <button className='bg-[#3346D3] p-4 rounded-lg flex  items-center justify-center space-x-2 '>
            <img src={WhiteDot} alt="white dot" />
            <img src={WhiteDot} alt="white dot" />
            <img src={WhiteDot} alt="white dot" />
          </button> */}
        </div>
      </div>
    </>
  );
};

export default memo(Artboard);
