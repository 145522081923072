import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';

const Heading = ({ id }) => {
  const { t } = useTranslation();
  const heading = t(`builder.sections.${id}`);

  return <h2 className="text-4xl focus:outline-none font-sans">{heading}</h2>;
};

export default memo(Heading);
