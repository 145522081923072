import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const SkillItem = ({ id, name, level }) => (
  <div key={id} className="flex flex-col mb-3">
    <h6 className="font-semibold text-xs">{name}</h6>
    <span className="text-xs">{level}</span>
  </div>
);

const SkillsC = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.skills) ? (
    <div>
      <Heading>
        <h1 className="text-xl mb-2">{data.skills.heading}</h1>
      </Heading>
      <div className="flex flex-col">
        {data.skills.items.map((x) => isItemVisible(x) && SkillItem(x))}
      </div>
    </div>
  ) : null;
};

export default memo(SkillsC);
