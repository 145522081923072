import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
// import * as styles from './builder.module.css';
import { useDispatch } from '../../contexts/ResumeContext';
import Artboard from '../../components/builder/center/Artboard';
import Button from '../../components/shared/Button';
import DatabaseContext from '../../contexts/DatabaseContext';
import LeftSidebar from '../../components/builder/left/LeftSidebar';
import LoadingScreen from '../../components/router/LoadingScreen';
import RightSidebar from '../../components/builder/right/RightSidebar';
import SettingsContext from '../../contexts/SettingsContext';
import NavBar from '../../components/builder/component/NavBar';

const Builder = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { getResume } = useContext(DatabaseContext);
  const { isSideBarOpen } = useContext(SettingsContext);
  const [openTemp, setOpenTemp] = useState(false);
  const [preview, setPreview] = useState(false);

  const handleLoadDemoData = () => {
    dispatch({ type: 'load_demo_data' });
  };

  const OpenPreview = () => {
    // console.log('open preview');
    if (preview === false) {
      setPreview(true);
    } else {
      setPreview(false);
    }
  };

  useEffect(() => {
    (async () => {
      const resume = await getResume(id);

      if (!resume) {
        navigate('/app/dashboard');
        toast.error(t('builder.toasts.doesNotExist'));
        return null;
      }

      if (resume.createdAt === resume.updatedAt) {
        toast.dark(() => (
          <div className="py-2">
            <p className="leading-loose">{t('builder.toasts.loadDemoData')}</p>

            <Button className="mt-4" onClick={handleLoadDemoData}>
              {t('builder.actions.loadDemoData.button')}
            </Button>
          </div>
        ));
      }

      dispatch({ type: 'set_data', payload: resume });
      return setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    console.log(openTemp);
  }, [openTemp]);

  return useMemo(() => {
    if (loading) {
      return <LoadingScreen />;
    }

    return (
      <>
        <NavBar />
        <div className="relative w-full flex items-start justify-center overflow-hidden mt-[-60px] ">
          <div className="w-full md:w-[60%]">
            <LeftSidebar />
          </div>
          <div
            className={`${
              preview === true ? 'flex' : 'hidden'
            } absolute w-[98%] md:relative md:bl md:w-[40%] md:flex items-start `}
          >
            <Artboard setOpenTemp={setOpenTemp} />
          </div>
          <button
            onClick={() => OpenPreview()}
            className="fixed bottom-0 w-full bg-[#3346d3] md:hidden text-white text-[14px] font-semibold py-4"
          >
            {preview === true ? 'CLOSE' : 'PREVIEW'}
          </button>

          <div
            className={`${
              openTemp ? 'flex' : 'hidden'
            } w-full h-full fixed bg-[rgba(0,0,0,0.4)] top-0`}
          >
            <div className="w-[98%] md:w-[50%] bg-white h-[80%] overflow-hidden mx-auto mt-14">
              <RightSidebar setOpenTemp={setOpenTemp} />
            </div>
          </div>
        </div>
      </>
    );
  }, [loading, isSideBarOpen, openTemp, preview]);
};

export default memo(Builder);
