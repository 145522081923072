import { Link } from 'react-scroll';
// import { Tooltip } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import React, { memo } from 'react';
// import * as styles from './SectionIcon.module.css';

const SectionIcon = ({ section, containerId }) => {
  // const { t } = useTranslation();
  const { id, icon: Icon } = section;

  return (
    <>
      <Link
        spy
        className='flex justify-center items-center align-middle flex-col shadow-lg bg-white py-4 cursor-pointer hover:no-underline space-y-1 min-w-[100px]'
        // smooth
        to={id}
        offset={-18}
        duration={500}
        containerId={containerId}
        activeClass="text-[#3346D3]"
      >
        <Icon size="18px" /><span>{id}</span>
      </Link>
    </>
  );
};

export default memo(SectionIcon);
