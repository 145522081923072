import { Link } from 'gatsby';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { memo, useCallback } from 'react';
import Logo from '../../shared/Logo';
// import * as styles from './LeftNavbar.module.css';
import SectionIcon from '../../shared/SectionIcon';
import sections from '../../../data/leftSections';

const LeftNavbar = () => {
  const { t } = useTranslation();

  const newSections = sections.map(
    useCallback((section, index) => ({
      ...section,
      id: t(`builder.tooltips.sections.${index}`),
    })),
    [sections],
  );

  return (
    <div className="w-full md:w-[30%] bg-white flex justify-center items-center flex-col rounded-lg pt-10 shadow-lg h-[200px] md:h-[590px] ">
      <Tooltip
        className="hidden"
        title={t('builder.tooltips.backToDashboard')}
        placement="right"
      >
        <div>
          <Link to="/app/dashboard">
            <Logo size="40px" />
          </Link>
        </div>
      </Tooltip>

      <h2 className="text-center text-[#3346D3] text-[18px] font-semibold">
        {t('builder.tooltips.title')}
      </h2>
      <p className="text-[#9093AC] text-center text-[14px] font-normal px-4 mb-6">
        {t('builder.tooltips.subtitle')}
      </p>
      <div className="flex overflow-x-scroll space-x-6 md:space-x-0 md:grid md:grid-cols-2 md:gap-1 text-primary-500 w-full py-6 md:py-0">
        {newSections.map((x) => (
          <SectionIcon
            key={x.id}
            section={x}
            containerId="LeftSidebar"
            tooltipPlacement="right"
          />
        ))}
      </div>
      {/* <button className='text-white bg-[#3346D3] rounded-lg w-3/4 py-4 mt-6 shadow-lg'>
        New Section
      </button> */}
    </div>
  );
};

export default memo(LeftNavbar);
