const templateOptions = [
  {
    id: 'onyx',
    name: 'Onyx',
  },
  {
    id: 'pikachu',
    name: 'Pikachu',
  },
  {
    id: 'gengar',
    name: 'Gengar',
  },
  {
    id: 'castform',
    name: 'Castform',
  },
  {
    id: 'glalie',
    name: 'Glalie',
  },
  {
    id: 'celebi',
    name: 'Celebi',
  },
  {
    id: 'tokyo',
    name: 'Tokyo',
  },
  {
    id: 'Amsterdam',
    name: 'Amsterdam',
  },
  {
    id: 'Berlin',
    name: 'Berlin',
  }
];

export default templateOptions;
