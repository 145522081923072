import { Element } from 'react-scroll';
import React, { Fragment, memo, useContext } from 'react';
// import * as styles from './LeftSidebar.module.css';
import { useTranslation } from 'react-i18next';
import Awards from './sections/Awards';
import Certifications from './sections/Certifications';
import Education from './sections/Education';
import Hobbies from './sections/Hobbies';
import Languages from './sections/Languages';
import LeftNavbar from './LeftNavbar';
import Objective from './sections/Objective';
import Profile from './sections/Profile';
import Projects from './sections/Projects';
import References from './sections/References';
import Skills from './sections/Skills';
import Social from './sections/Social';
import Work from './sections/Work';
import sections from '../../../data/leftSections';
import SettingsContext from '../../../contexts/SettingsContext';

const getComponent = (id) => {
  switch (id) {
    case 'profile':
      return Profile;
    case 'social':
      return Social;
    case 'objective':
      return Objective;
    case 'work':
      return Work;
    case 'education':
      return Education;
    case 'projects':
      return Projects;
    case 'awards':
      return Awards;
    case 'certifications':
      return Certifications;
    case 'skills':
      return Skills;
    case 'hobbies':
      return Hobbies;
    case 'languages':
      return Languages;
    case 'references':
      return References;
    default:
      throw new Error();
  }
};

const SidebarSection = ({ id, event }) => {
  const Component = getComponent(id);

  return (
    <Fragment key={id}>
      <div name={id} className="w-full py-3">
        <Element name={id}>
          <Component id={id} event={event} />
        </Element>
      </div>
      <hr />
    </Fragment>
  );
};

const LeftSidebar = () => {
  const { isSideBarOpen } = useContext(SettingsContext);
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap md:flex-nowrap justify-around ">
      <LeftNavbar />
      {isSideBarOpen && (
        <div className="w-full md:w-[65%] bg-white rounded-lg shadow-lg overflow-hidden max-h-[87vh]">
          <h3 className="w-full p-6 text-[#262B33] text-[21px] font-semibold">
            {t('builder.tooltips.resumeTitle')}
          </h3>
          <div className="px-4 hidden">
            <h2 className="flex text-[#9AA3B3] text-[16px]">
              <span className="text-[#FC4E51] ">10%</span> Profile complete
            </h2>
            <div className="w-full bg-[#F2F5FA] h-1 my-3 rounded-full">
              <div className="w-[10%] bg-[#FC4E51] h-1 rounded-full" />
            </div>
          </div>
          <div
            id="LeftSidebar"
            className="h-[100vh] w-full overflow-y-scroll px-6"
          >
            {sections.map(SidebarSection)}
            <div className="h-[620px]" />
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(LeftSidebar);
