import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import { formatDate, isItemVisible, safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const AwardItem = ({ item, language }) => (
  <div>
    <div className="flex flex-col justify-start items-start">
      <div className="w-full flex text-left ">
        <h6 className="font-semibold text-sm">{item.title}</h6>
      </div>
      <div className="w-full flex flex-row gap-1 text-left ">
        <span className="text-xs">{item.awarder}</span>
        {item.date && (
          <h6 className="text-xs font-medium text-right">
            ({formatDate({ date: item.date, language })})
          </h6>
        )}
      </div>
    </div>
    {item.summary && (
      <Markdown className="markdown mt-2 text-sm">{item.summary}</Markdown>
    )}
  </div>
);

const AwardsB = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.awards) ? (
    <div className="mb-4">
      <Heading>
        <h1 className="text-xl mb-2">{data.awards.heading}</h1>
      </Heading>
      <div className="flex flex-col">
        {data.awards.items.map(
          (x) =>
            isItemVisible(x) && (
              <AwardItem
                key={x.id}
                item={x}
                language={data.metadata.language}
              />
            ),
        )}
      </div>
    </div>
  ) : null;
};

export default memo(AwardsB);
