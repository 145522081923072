import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import React, { useContext } from 'react';
import {
    // hasAddress, hexToRgb,
    isItemVisible,
    safetyCheck,
} from '../utils';
import AwardsB from './blocks/Awards/AwardsB';
import CertificationsB from './blocks/Certifications/CertificationsB';
// import ContactB from './blocks/Contact/ContactB';
import EducationA from './blocks/Education/EducationA';
import HobbiesB from './blocks/Hobbies/HobbiesB';
import LanguagesC from './blocks/Languages/LanguagesC';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import ReferencesC from './blocks/References/ReferencesC';
import SkillsC from './blocks/Skills/SkillsC';
import WorkA from './blocks/Work/WorkA';
import Icons from './blocks/Icons';
import BirthDateC from './blocks/BirthDate/BirthDateC';
import HeadingB from './blocks/Heading/HeadingB';

const Blocks = {
    objective: ObjectiveA,
    work: WorkA,
    education: EducationA,
    projects: ProjectsA,
    awards: AwardsB,
    certifications: CertificationsB,
    skills: SkillsC,
    hobbies: HobbiesB,
    languages: LanguagesC,
    references: ReferencesC,
};

const ContactItem = ({ value, icon, link }) => {
    const { data } = useContext(PageContext);
    const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);

    return value ? (
        <div className="flex items-center">
            <Icon
                size="10px"
                className="mr-2"
                style={{ color: data.metadata.colors.primary }}
            />
            {link ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <span className="font-medium break-all">{value}</span>
                </a>
            ) : (
                <span className="font-medium break-all">{value}</span>
            )}
        </div>
    ) : null;
};

const Berlin = ({ data }) => {
    const { t } = useTranslation();
    const layout = data.metadata.layout.gengar;
    //   const { r, g, b } = hexToRgb(data.metadata.colors.primary) || {};

    const Photo = () =>
        data.profile.photograph !== '' && (
            <img
                className="w-36 h-36 z-30 rounded-full mx-auto mb-14 object-cover border-4"
                style={{
                    borderColor: data.metadata.colors.background,
                }}
                src={data.profile.photograph}
                alt={data.profile.firstName}
            />
        );

    const Profile = () => (
        <div>
            <h1 className="text-4xl font-bold leading-tight">
                {data.profile.firstName} {data.profile.lastName}
            </h1>
            <div className="text-xs font-medium mt-2">{data.profile.subtitle}</div>
        </div>
    );

    return (
        <PageContext.Provider value={{ data, heading: HeadingB }}>
            <div
                id="page"
                className="rounded"
                style={{
                    fontFamily: data.metadata.font,
                    color: data.metadata.colors.text,
                    backgroundColor: data.metadata.colors.background,
                }}
            >

                <div
                    style={{
                        top: '0',
                        left: '0',
                        width: '100%',
                        overflow: 'hidden',
                        lineHeight: '0',
                    }}
                >
                    <div
                        className='relative w-full text-center pl-20 top-[30px]  z-20'
                        style={{
                            color: data.metadata.colors.background,
                        }} >
                        <Profile />
                    </div>
                    <svg className='relative top-[-60px] z-10' data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" style={{
                        position: 'relative',
                        display: 'block',
                        width: 'calc(100% + 1.3px)',
                        height: '200px',
                    }} viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" style={{
                            fill: data.metadata.colors.primary,
                        }} />
                    </svg>
                </div>
                <div className="relative top-[-256px] w-full flex">
                    <div className="container flex justify-between gap-6 ">
                        <div className="relative  w-1/4 bg-[#f4f4f4] flex flex-col gap-8 px-1">
                            <div className="container pb-10 flex flex-col gap-2 py-4 ">
                                <Photo />
                                <h1
                                    style={{
                                        fontFamily: data.metadata.font,
                                        color: data.metadata.colors.primary,
                                        borderBottom: `2px solid ${data.metadata.colors.primary}`,
                                    }}
                                    className={`text-xl font-semibold `}
                                >
                                    DETAILS
                                </h1>

                                <div className="flex flex-col gap-2 pb-4 pt-2  border-b-[1px]">
                                    <ContactItem
                                        label={t('shared.forms.email')}
                                        value={data.profile.email}
                                        icon="email"
                                        link={`tel:${data.profile.email}`}
                                    />

                                    <ContactItem
                                        label={t('shared.forms.address')}
                                        value={data.profile.address.line1}
                                        icon="address"
                                        link={`tel:${data.profile.address.line1}`}
                                    />

                                    <ContactItem
                                        label={t('shared.forms.phone')}
                                        value={data.profile.phone}
                                        icon="phone"
                                        link={`tel:${data.profile.phone}`}
                                    />

                                    <BirthDateC />
                                </div>

                                <h1
                                    style={{
                                        fontFamily: data.metadata.font,
                                        color: data.metadata.colors.primary,
                                        borderBottom: `2px solid ${data.metadata.colors.primary}`,
                                    }}
                                    className={`text-xl font-semibold  `}
                                >
                                    Links
                                </h1>
                                {safetyCheck(data.social) &&
                                    data.social.items.map(
                                        (x) =>
                                            isItemVisible(x) && (
                                                <ContactItem
                                                    key={x.id}
                                                    value={x.username}
                                                    icon={x.network}
                                                    link={x.url}
                                                />
                                            ),
                                    )}
                            </div>
                            {layout[0] &&
                                layout[0].slice(1, 2) &&
                                layout[0].slice(1, 2).map((x) => {
                                    const Component = Blocks[x];
                                    return Component && <Component key={x} />;
                                })}

                            <div
                                style={{
                                    color: data.metadata.colors.text,
                                }}
                                className="text-[12px] mt-3"
                            >
                                {layout[1] &&
                                    layout[1].map((x) => {
                                        const Component = Blocks[x];
                                        return Component && <Component key={x} />;
                                    })}
                            </div>
                        </div>
                        <div className="relative pt-[200px] w-3/4  border-r-[1px] pr-2">
                            <div
                                style={{
                                    color: data.metadata.colors.text,
                                }}
                                className="text-[12px] mt-2"
                            >
                                {layout[0] &&
                                    layout[0].slice(0, 1) &&
                                    layout[0].slice(0, 1).map((x) => {
                                        const Component = Blocks[x];
                                        return Component && <Component key={x} />;
                                    })}
                            </div>

                            <div
                                style={{
                                    color: data.metadata.colors.text,
                                }}
                                className="text-[12px] mt-3"
                            >
                                {layout[2] &&
                                    layout[2].map((x) => {
                                        const Component = Blocks[x];
                                        return Component && <Component key={x} />;
                                    })}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </PageContext.Provider>
    );
};

export default Berlin;
