import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaCaretRight } from 'react-icons/fa';
import React, { useContext } from 'react';
import {
  // hasAddress, hexToRgb,
  isItemVisible,
  safetyCheck,
} from '../utils';
import AwardsB from './blocks/Awards/AwardsB';
import CertificationsB from './blocks/Certifications/CertificationsB';
// import ContactB from './blocks/Contact/ContactB';
import EducationA from './blocks/Education/EducationA';
import HeadingC from './blocks/Heading/HeadingC';
import HobbiesB from './blocks/Hobbies/HobbiesB';
import LanguagesC from './blocks/Languages/LanguagesC';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import ReferencesC from './blocks/References/ReferencesC';
import SkillsC from './blocks/Skills/SkillsC';
import WorkA from './blocks/Work/WorkA';
import Icons from './blocks/Icons';
import BirthDateC from './blocks/BirthDate/BirthDateC';

const Blocks = {
  objective: ObjectiveA,
  work: WorkA,
  education: EducationA,
  projects: ProjectsA,
  awards: AwardsB,
  certifications: CertificationsB,
  skills: SkillsC,
  hobbies: HobbiesB,
  languages: LanguagesC,
  references: ReferencesC,
};

const ContactItem = ({ value, icon, link }) => {
  const { data } = useContext(PageContext);
  const Icon = get(Icons, icon && icon.toLowerCase(), FaCaretRight);

  return value ? (
    <div className="flex items-center">
      <Icon
        size="10px"
        className="mr-2"
        style={{ color: data.metadata.colors.primary }}
      />
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <span className="font-medium break-all">{value}</span>
        </a>
      ) : (
        <span className="font-medium break-all">{value}</span>
      )}
    </div>
  ) : null;
};

const Gengar = ({ data }) => {
  const { t } = useTranslation();
  const layout = data.metadata.layout.gengar;
  //   const { r, g, b } = hexToRgb(data.metadata.colors.primary) || {};

  const Photo = () =>
    data.profile.photograph !== '' && (
      <img
        className="w-24 h-24 rounded-full mr-4 object-cover border-4"
        style={{
          borderColor: data.metadata.colors.background,
        }}
        src={data.profile.photograph}
        alt={data.profile.firstName}
      />
    );

  const Profile = () => (
    <div>
      <h1 className="text-2xl font-bold leading-tight">
        {data.profile.firstName}
      </h1>
      <h1 className="text-2xl font-bold leading-tight">
        {data.profile.lastName}
      </h1>
      <div className="text-xs font-medium mt-2">{data.profile.subtitle}</div>
    </div>
  );

  return (
    <PageContext.Provider value={{ data, heading: HeadingC }}>
      <div
        id="page"
        className="rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          backgroundColor: data.metadata.colors.background,
        }}
      >
        <div className="flex w-full flex-col">
          <div className="w-full flex ">
            <div
              className="container flex mx-auto p-10"
              style={{
                backgroundColor: data.metadata.colors.primary,
                color: data.metadata.colors.background,
              }}
            >
              <Photo />
              <Profile />
            </div>
          </div>

          <div className="w-full flex">
            <div className="container flex gap-12 py-4 mx-6 border-b-[1px]">
              <ContactItem
                label={t('shared.forms.email')}
                value={data.profile.email}
                icon="email"
                link={`tel:${data.profile.email}`}
              />

              <ContactItem
                label={t('shared.forms.address')}
                value={data.profile.address.line1}
                icon="address"
                link={`tel:${data.profile.address.line1}`}
              />

              <ContactItem
                label={t('shared.forms.phone')}
                value={data.profile.phone}
                icon="phone"
                link={`tel:${data.profile.phone}`}
              />

              <BirthDateC />
            </div>
          </div>

          <div className="w-full flex">
            <div className="container flex gap-6  mx-6 ">
              <div className="w-3/4 pt-4 border-r-[1px] pr-2">
                <div
                  style={{
                    color: data.metadata.colors.text,
                  }}
                  className="text-[12px] mt-2"
                >
                  {layout[0] &&
                    layout[0].slice(0, 1) &&
                    layout[0].slice(0, 1).map((x) => {
                      const Component = Blocks[x];
                      return Component && <Component key={x} />;
                    })}
                </div>

                <div
                  style={{
                    color: data.metadata.colors.text,
                  }}
                  className="text-[12px] mt-3"
                >
                  {layout[2] &&
                    layout[2].map((x) => {
                      const Component = Blocks[x];
                      return Component && <Component key={x} />;
                    })}
                </div>
              </div>
              <div className="w-1/4 pt-6 flex flex-col gap-8">
                <div className="container flex flex-col gap-2 py-4 ">
                  <h1
                    style={{
                      fontFamily: data.metadata.font,
                      color: data.metadata.colors.text,
                    }}
                    className="text-xl font-semibold"
                  >
                    Links
                  </h1>
                  {safetyCheck(data.social) &&
                    data.social.items.map(
                      (x) =>
                        isItemVisible(x) && (
                          <ContactItem
                            key={x.id}
                            value={x.username}
                            icon={x.network}
                            link={x.url}
                          />
                        ),
                    )}
                </div>
                {layout[0] &&
                  layout[0].slice(1, 2) &&
                  layout[0].slice(1, 2).map((x) => {
                    const Component = Blocks[x];
                    return Component && <Component key={x} />;
                  })}

                <div
                  style={{
                    color: data.metadata.colors.text,
                  }}
                  className="text-[12px] mt-3"
                >
                  {layout[1] &&
                    layout[1].map((x) => {
                      const Component = Blocks[x];
                      return Component && <Component key={x} />;
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="col-span-4 px-6 py-8"
            style={{
              backgroundColor: data.metadata.colors.primary,
              color: data.metadata.colors.background,
            }}
          >
            {hasAddress(data.profile.address) && (
              <div className="flex flex-col mt-4 text-xs">
                <h6 className="font-bold text-xs uppercase tracking-wide mb-1">
                  {t('shared.forms.address')}
                </h6>
                <span>{data.profile.address.line1}</span>
                <span>{data.profile.address.line2}</span>
                <span>
                  {data.profile.address.city} {data.profile.address.pincode}
                </span>
              </div>
            )}

            <hr
              className="w-1/4 my-5 opacity-25"
              style={{ borderColor: data.metadata.colors.background }}
            />

            <h6 className="font-bold text-xs uppercase tracking-wide mb-2">
              {t('shared.forms.contact')}
            </h6>

            <ContactB />
          </div> */}

          {/* <div
            className="col-span-8 px-6 py-8"
            style={{ backgroundColor: `rgba(${r}, ${g}, ${b}, 0.1)` }}
          >
            <div className="grid gap-6 items-center">
              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div> */}
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default Gengar;
