import React, { memo, useContext } from 'react';
import { isItemVisible, safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const HobbyA = ({ id, name }) => (
  <div key={id}>
    <h6 className=" text-sm">{name}</h6>
  </div>
);

const HobbiesB = () => {
  const { data, heading: Heading } = useContext(PageContext);

  return safetyCheck(data.hobbies) ? (
    <div className="mb-4">
      <Heading>
        <h1 className="text-xl mb-2">{data.hobbies.heading}</h1>
      </Heading>
      <div className="grid gap-2">
        {data.hobbies.items.map((x) => isItemVisible(x) && HobbyA(x))}
      </div>
    </div>
  ) : null;
};

export default memo(HobbiesB);
