import React from 'react';
import { Link } from 'gatsby';
import Avatar from '../../shared/Avatar';
import logo from '../../../../static/images/logo-white.svg';

export default function NavBar() {
  return (
    <div className="w-full bg-[#3346D3] pb-20">
      <div className="flex justify-between container py-6">
        <div className="flex justify-left space-x-2 items-center w-2/4 md:w-3/12 ml-6">
          {' '}
          <Link
            to="/"
            className="font-semibold  text-[18px] text-white hover:no-underline"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="logo" className="mr-2" />
              SimpleCV
            </div>
          </Link>
        </div>

        <div className="w-full md:w-[20%] flex justify-end md:justify-end items-center">
          <div className="border-none pr-6">
            <Avatar className="ml-8 border-[1px] border-solid border-white" />
          </div>
        </div>
      </div>
    </div>
  );
}
